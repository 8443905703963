import { useEffect, useState } from 'react';
import styles from './Hero.module.scss';

type Props = {
  title: string;
  subTitle: string;
  text: string;
};

const Title = ({ title, subTitle, text }: Props) => {
  const [ptid, setPtid] = useState<string | null>();
  const [pdid, setPdid] = useState<string | null>();

  useEffect(() => {
    const originPage = sessionStorage.getItem('substitutionPage');
    if (!originPage || originPage === 'main-page') {
      sessionStorage.setItem('substitutionPage', 'main-page');
      const urlParams = new URLSearchParams(window.location.search);
      setPtid(urlParams.get('ptid'));
      setPdid(urlParams.get('pdid'));
    }
  }, []);

  return (
    <>
      <h1 className={styles.headline}>{ptid || title}</h1>
      <h2 className={styles.subtitle}>{subTitle}</h2>
      <p className={styles.text}>{pdid || text}</p>
    </>
  );
};

export default Title;
